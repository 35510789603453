/* Variables */
$lt-mobile: '37.4375rem';
$gt-mobile: '37.5rem';
$lt-desktop: '61.9375rem';
$gt-desktop: '62rem';
$lt-xl-desktop: '124.9375rem';
$gt-xl-desktop: '75rem';

$primary-color: #185A9D;
$primary-color-lighten: #1A62AB;
$primary-color-darken: #144C85;
$secondary-color: #2387EB;
$secondary-color-lighten: #258EF7;
$secondary-color-darken: #1F78D1;