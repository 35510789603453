@import "variables";

/* Global Styling */
html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2.25rem;
}

a {
    &:hover {
        text-decoration: underline;
    }
}

dl {
    div {
        display: flex;
        flex-direction: row;
        justify-content: start;

        dd {
            margin-left: 1rem;
        }
    }
}

strong {
    font-weight: bolder;
}

header {
    height: 4rem;
    position: fixed;
    top: 0;
    left: 0;
    transition: .1s ease-in;
    width: 100%;
    z-index: 10;

    &.scrolled {
        background-color: #0E365E;
        box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
    }

    nav {
        background-color: transparent;
        box-shadow: none;
        padding: 0 1rem;

        ul {
            height: 100%;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            li {
                border-radius: 31.25rem;
                display: inline-block;
                line-height: initial;
                margin-top: -0.25rem;

                &.active {
                    background-color: rgba(255,255,255,.15);
                }

                a {
                    border-radius: 31.25rem;
                    color: #FFFFFF;
                    padding: 0.75rem 1.5rem;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        .sidenav-trigger {
            margin: 0;
        }
    }

    .logo {
        height: 2rem;
        margin-bottom: -0.25rem;
        width: auto;
    }
}

#mobile-menu {
    background-color: #0E365E;
    width: 80vw;

    li {
        a {
            color: #FFFFFF;
        }
    }
}

main {
    background-image: linear-gradient(125deg, #2387EB, #0E365E 0%, #000000 100%);
    color: #FFFFFF;
    font-size: 1.25rem;
    width: 100%;

    section {
        display: flex;
        flex-direction: column;
        justify-content: center;

        section {
            margin: auto;
            padding: 4rem 0;
            width: 80%;

            @media (max-width: $lt-mobile) {
                padding: 1rem;
                width: 100%;
            }
        }
    }
}

footer {
    background-color: #EEEEEE;
    border-top: 1rem solid $secondary-color;
    color: #000000;
    padding: 4rem 0 calc(2rem - 1.25rem) 0;
    width: 100%;

    section {
        width: 80%;

        @media (max-width: $lt-desktop) {
            width: 90%;
        }

        @media (max-width: $lt-mobile) {
            width: 100%;
        }

        .col {
            @media (max-width: $lt-mobile) {
                margin-bottom: 1rem;
            }
        }

        .org-logo {
            display: block;
            height: 3rem;
            width: auto;
        }

        h2 {
            font-size: 1.5rem;
            margin-top: initial;
        }
    }
}

a.button, button {
    border-radius: 31.25rem !important;
    font-weight: bold;

    &:hover {
        text-decoration: none;
    }

    @media (max-width: $lt-desktop) {
        width: 100%;
    }

    &.primary {
        background-color: $primary-color;
        color: #FFFFFF;

        &:hover {
            background-color: $primary-color-lighten;
        }
    }

    &.secondary {
        background-color: $secondary-color-darken;
        color: #FFFFFF;

        &:hover {
            background-color: $secondary-color-lighten;
        }
    }

    &.black {
        background-color: #000000;
        color: #FFFFFF;

        &:hover {
            background-color: #333333;
        }
    }
}

form {
    label {
        margin-left: 1rem !important;

        &.active {
            color: inherit !important;
            transform: translateY(-24px) scale(0.8) !important;
        }
    }

    input, textarea {
        background-color: #FFFFFF !important;
        border-bottom: none !important;
        border-radius: 32px !important;
        padding: 0 1rem !important;
        width: calc(100% - 2rem) !important;
    }

    textarea {
        font-size: 1rem;
        padding: 0.75rem 1rem !important;
        width: 100% !important;
    }
}

#not-found {
    min-height: 100vh;
    overflow: hidden;
    position: relative;

    img {
        height: auto;
        max-height: 80vh;
        opacity: .25;
        position: absolute;
        right: 0;
        user-select: none;
        width: 60%;
        z-index: 0;

        @media (max-width: $lt-xl-desktop) {
            right: -5%;
            width: 80%;
        }

        @media (max-width: $lt-desktop) {
            right: -50%;
            width: 150%;
        }

        
    }

    section {
        margin-bottom: auto;

        div {
            margin-left: 0;
            z-index: 1;

            h1 {
                font-size: 8rem;
                font-weight: bold;
                letter-spacing: .1rem;
        
                @media (max-width: $lt-desktop) {
                    font-size: 5rem;
                }
                
                @media (max-width: $lt-mobile) {
                    font-size: 3rem;
                }

                span {
                    display: inline;
                    opacity: .1;
                }
            }
        
            p {
                margin: 3rem auto;
            }
        }
    }
}